.ScrollLine {
    padding-top: 5vh;
    padding-bottom: 10vh;
    position: relative;
}

.firstLineTeacher {
    width: 100%;
}
.parallaxTeacher {
    overflow: hidden;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
}

.bg-pattern {
    background-image: url(../../assets/pattern-carousel.svg);
    background-repeat: repeat-x;
    background-size: cover;
    background-position: center center;
}
.parallaxTeacher .scroller {
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    gap: 2rem;
}

.parallaxTeacher span {
    display: block;
}

.lineTeacher {
    display: flex;
    align-items: start;
    width: fit-content;
    justify-content: space-between;
    gap: 4rem;
}
.lineTeacher p {
    margin: 0;
    padding: 0;
    margin-left: 1.6rem;
}
.img-container {
    width: 200px;
    height: 200px;
    border-radius: 24px 0px;
    overflow: hidden;
}

.img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
