#Layer_2222 path {
    transform-box: fill-box;
    transform-origin: center center;
}
.st0 {
    opacity: 0.35;
    fill: #f4a795;
}
.st1 {
    fill: #8480bd;
}
.st2 {
    fill: #d5cbfb;
}
.st3 {
    fill: #90351c;
}
.st4 {
    fill: #fe6239;
}
.st5 {
    fill: #7258a6;
}
.st6 {
    fill: #d8a32b;
}
.st7 {
    fill: #140433;
}
.st8 {
    fill: #fff6dc;
}
.st9 {
    fill: #ffffff;
}
.st10 {
    fill: #f4e7bd;
}
.st11 {
    fill: #f0f0f5;
}
.st12 {
    fill: #f5f5ff;
}
.st13 {
    fill: none;
    stroke: #8480bd;
    stroke-width: 2.1457;
    stroke-miterlimit: 10;
}
.st14 {
    fill: none;
    stroke: #fe6239;
    stroke-width: 1.9001;
    stroke-miterlimit: 10;
}
